.card-session,
.card-register {
  background-color: #080F23;
  border-radius: 0px;
  color: #fff;
  max-width: 400px;
  margin: 20px 0 70px;
  min-height: 350px;
  padding: 60px;

  & label {
    margin-top: 15px;
  }
  & .card-title {
    color: #B33C12;
    text-align: center;
  }
  & .btn {
    margin-top: 30px;
  }
  & .forgot {
    text-align: center;
  }

  .input-group-text {
    border: 1px solid $white-color;
  }
}

.card-group-sign-up {
  background-color: #080F23;
  border-radius: 0px;
  color: #fff;
  margin: 20px 0 0;
  min-height: 350px;
  padding: 60px;

  @media screen and (max-width: 500px) {
    padding: 30px;
  }
  
  & label {
    margin-top: 15px;
    font-weight: 400;
  }
  & .card-title {
    color: #B33C12;
    text-align: center;
  }
  & .forgot {
    text-align: center;
  }

  .input-group-text {
    border: 1px solid $white-color;
  }

  .recaptcha-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0 40px;
    flex-direction: column;

    .recaptcha-error {
      display: flex;
      justify-content: center;    
    }
  }

  .form-button {
    display: flex;
    justify-content: center;
    align-items: center;

    .spinner-border {
      margin-right: 10px;
    }
  }

  .dropdown {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: space-between;

    .btn {
      margin: 10px 0 0 20px;
    }
  }

  .text-danger {
    margin-top: 3px;
    font-size: 12px;
    font-weight: 400;
    position: absolute;
  }

  .dropdown.show .dropdown-menu {
    background: white!important;
  }
}