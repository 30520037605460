.page-header{
    background-color: #B2AFAB;
    background-position: center center;
    background-size: cover;
    min-height: 100vh;
    max-height: 999px;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .content-center{
        bottom: 6vh;
        position: absolute;
    }

    .filter::after{
        background: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(232,232,235,0) 20%, rgba(0,0,0,0.5116640406162465) 100%, rgba(0,212,255,1) 100%);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
    }
    &.page-header-small{
        min-height: 65vh !important;
        max-height: 700px;
    }
    &.page-header-xs{
        min-height: 40vh !important;
    }

    &.section-dark{
        .content-center,
        .category-absolute{
            z-index: 1;
        }
    }
}

.page-header .motto {
    text-align: left;
    z-index: 3;
    color: #fff;
    position: relative;

}

.section-buttons {
    z-index: 1;
}

.landing-section .btn-simple {
    padding: 0;
}

.landing-section .column {
    padding: 0 75px 0 25px;
}

.title-brand{
    max-width: 730px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    color: #FFFFFF;
    display: block;

    .type{
        position: absolute;
        font-size: 20px;
        background: #132026;
        padding: 6px 10px;
        border-radius: 4px;
        top: 0;
        font-weight: 900;
        margin-top: 10px;
        right: -15px;
    }
}

.presentation-subtitle {
    position: relative;
    bottom: 40vh; 
    font-size: 2.5em;
    color: white;
    font-weight: 400;
    text-shadow: 0 0 50px rgba(0,0,0,0.6), 0 0 50px rgba(0,0,0,0.6), 0 0 50px rgba(0,0,0,0.6), 0 0 5px rgba(0,0,0,1),;

    @media screen and (min-width: 500px) {
        font-size: 3em;
    }
}

.img-published {
    height: 328px;
    
    @media screen and (max-width: 500px) {
            height: 220px;
    }
}

.img-planeta {
    max-width: 300px;
}

.fog-low {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: -55%;
    width: 110%;
    opacity: .6;

    img{
        width: 100%;
    }

    &.right{
        margin-left: 40%;
        margin-bottom:  60px;
        opacity: .3;
    }
}

.moving-clouds {
    position: absolute;
    z-index: 0;
    bottom: 100px;
    left: 0;
    width: 250.625em;
    height: 43.75em;
    -webkit-animation: cloudLoop 120s linear infinite;
    animation: cloudLoop 120s linear infinite;
}

@keyframes cloudLoop {
    0%{-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}
    50%{-webkit-transform:translate3d(-50%,0,0);
    transform:translate3d(-50%,0,0)}
}

.fa-facebook-official::before {
    color: #4267B2;
}

.img-border-big {
    border: 7px solid #fff;
}

.scroll-down-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.field {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 300px;
}

.scroll-down-wrapper {
    position: absolute;
    bottom: 50px;
    width: 100%;
    z-index: 108;
    text-align: center;
    cursor: pointer;
}

.scroll {
    margin-top: 20px;
	width: 60px;
	height: 60px;
	border: 2px solid #fff;
	border-radius: 50%;
	position: relative;
	animation: down 1.5s infinite;
	-webkit-animation: down 1.5s infinite;

	&::before {
		content: '';
		position: absolute;
		top: 15px;
		left: 18px;
		width: 18px;
		height: 18px;
		border-left: 2px solid #fff;
  	    border-bottom: 2px solid #fff;
		transform: rotate(-45deg);
	}
}

@keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}

@-webkit-keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}

@keyframes scroll {
	0% {
		height: 40px;
	}
	30% {
		height: 70px;
	}
	60% {
		height: 40px;
	}
}

@-webkit-keyframes scroll {
	0% {
		height: 40px;
	}
	30% {
		height: 70px;
	}
	60% {
		height: 40px;
	}
}

.swiper-testimonials {
    margin-top: 6em;
}

.testimonial-header {
    color: white;
}

.testimonial-item {
    display: flex!important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 20px 25px;
    background-color: #ddd;
    min-height: 550px;
    cursor: grab;
    margin: 0 30px 0 45px;
}

.testimonial-text {
    font-style: italic;
}

.testimonial-name {
    font-weight: bold;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-wrapper {
    display: flex;
    flex-direction: row;
}
  
.swiper-slide {
    min-width: 250px;
    max-width: 250px;

    @media screen and (min-width: 350px) {
        min-width: 300px;
        max-width: 300px;
    }
}

.swiper-pagination-bullet.swiper-pagination-bullet-active.swiper-pagination-bullet-active-main {
    background-color: #3c3939;
}

// HAMBURGER MENU
.navbar-toggler {
    border: none;
    background: transparent;
    outline: none;
  
    .navbar-toggler-icon {
      display: inline-block;
      width: 1.5em;
      height: 1.5em;
      vertical-align: middle;
      content: "";
      background: 50%/100% 100% no-repeat;
      transition: transform 0.3s ease-in-out;
      position: relative;
      transform-origin: center;
    }
  
    &.collapsed .navbar-toggler-icon {
      transform: rotate(0deg);
    }
  
    &.toggled .navbar-toggler-icon {
      transform: rotate(45deg);
    }
  
    &.toggled .navbar-toggler-icon::before {
      transform: rotate(90deg);
    }
  
    &.toggled .navbar-toggler-icon::after {
        transform: translate(1px, -8px)
    }
}
  
.navbar-toggler-icon::before,
.navbar-toggler-icon::after {
    content: "";
    display: block;
    width: 1.5em;
    height: 0.1em;
    background-color: white;
    position: absolute;
    transition: transform 0.3s ease-in-out;
}
  
.navbar-toggler-icon::before {
    top: 0.5em;
}

.navbar-toggler-icon::after {
    top: 0.9em;
}

.swiper-button-next::after,
.swiper-button-prev:after {
    color: rgb(139, 122, 135);
}

.navbar-toggler-icon::before,
.navbar-toggler-icon::after {
    background-color: #66615B;
}

.navbar-transparent .navbar-toggler-icon::before,
.navbar-transparent .navbar-toggler-icon::after {
    background-color: white;
}

.section {
    &.sub-page {
        background-color: $light-gray;
        min-height: calc(100vh - 85px);
    }

    &.section-o-mnie {
        background-color: #101007;

        p, li {
            color: $fill-font-color;
        }
    }

    &.section-dobrobycie,
    &.section-about-psychk {
        p {
            color: $fill-font-color;
        }
    }

    &.section-workshops {
        h1, p {
            color: $fill-font-color;
        }
    }
}

// .page-content {
//     > .container {
//         margin-top: 5em;
//         margin-bottom: 5em;
//     }

//     p, h1, h2, h3, h4, h5, h6 {
//         margin-bottom: 1em;
//     }

//     h4, h5, h6 {
//         font-weight: 900;
//     }

//     ol {
//         margin-bottom: 1em;
//         font-size: 18px;
//         font-weight: 400;
//     }

//     blockquote {
//         font-size: 18px;
//         font-weight: 400;
//         margin: 2em 0;
//         padding: 0 0 0 1.25em;
//         border-left: 5px solid #66615B;
//         font-style: italic;
//     }

//     .card {
//         color: inherit;
//         font-weight: 300;

//         ul {
//             margin-top: 0.5em;

//             li {
//                 font-size: 0.95em;
//                 line-height: 1.35em;
//                 font-weight: 400;
//             }
//         }
//     }
// }